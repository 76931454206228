import useAppRouter from '@hooks/useAppRouter';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import useTranslation from 'next-translate/useTranslation';
import {
  componentIsStoreInfoComponentDataType,
  Features,
  StoreContactInfo,
  StoreFeatures,
  StoreLocation,
  StoreOpeningHours,
  StoreSpecialOpeningDays,
} from '../types';
import { convertLocalBusiness, StructuredLocalBusiness } from './formatLocalBusinessStructuredData';
import { getSpecialOpeningDays, getStoreLocation, getStoreOpeningHours, rewriteStoreFeatures } from './helpers';

export interface StoreInfoCmsData {
  storeName: string;
  longitude?: string;
  latitude?: string;
  openStatusKey?: string;
  openingHours?: string;
  storeFeatures: Features;
  storeLocation: StoreLocation;
  storeContact: StoreContactInfo;
  storeOpeningHours: StoreOpeningHours;
  storeSpecialOpeningDays: StoreSpecialOpeningDays;
  streetAddress?: string;
  postalCode?: string;
  town?: string;
  flyerURL?: string;
  localBusinessStructuredData?: StructuredLocalBusiness;
}
export type StorePageInfo =
  | { hasError: true; storeData?: undefined }
  | { hasError: false; storeData: StoreInfoCmsData | undefined };

const messageKeyMap: { [key: string]: string } = {
  'store.openMessage.open': 'open',
  'store.openMessage.soon': 'newStoreOpens',
  'store.openMessage.notOpen': 'newStoreOpensSoon',
};

const useStoreInfoCmsData = (rightContentComponents: MultiColumnContainerComponentType[]): StorePageInfo => {
  const columnContainerData = rightContentComponents[0].columnContainerData as ColumnContainerData;
  const componentIds = columnContainerData.cmsComponents;

  const { query } = useAppRouter();
  const storeId = (typeof query.id === 'string' && query.id) || '';

  const { data, error: hasError } = useCmsComponents(componentIds, storeId);

  const { t } = useTranslation('store');

  if (!!hasError) {
    throw new Error('Failed to get store info data');
  }
  const firstComponent = data?.[0];

  const storeInfo = componentIsStoreInfoComponentDataType(firstComponent) ? firstComponent : undefined;

  // TODO: add any value that is needed to even be able to render the component
  if (!!storeInfo && !!storeInfo.store && !!storeInfo.store.displayName) {
    const {
      store,
      openingHoursMessageKey: rawMessageKey,
      openingStoreMessageValue: rawOpeningHours,
      storeFeatures,
      specialOpeningDays,
    } = storeInfo;
    const { geoPoint, flyerURL, address } = store;

    const rewrittenStoreFeatures: StoreFeatures = (storeFeatures && rewriteStoreFeatures(storeFeatures)) || {};
    const features = Object.values(rewrittenStoreFeatures);

    const localBusinessStructuredData = convertLocalBusiness(storeInfo);
    const storeOpeningHours = getStoreOpeningHours(t, storeInfo, rewrittenStoreFeatures);
    const storeSpecialOpeningDays = getSpecialOpeningDays(t, specialOpeningDays);
    const storeLocation = getStoreLocation(storeInfo);

    const storeContact = {
      phoneNumber: address?.phone || '',
      email: address?.email || '',
      visible: !!address?.phone || !!address?.email,
    };

    return {
      storeData: {
        storeName: storeInfo.store.displayName,
        longitude: geoPoint?.longitude,
        latitude: geoPoint?.latitude,
        openStatusKey:
          rawOpeningHours === '-' && rawMessageKey === 'store.openMessage.open'
            ? 'closed'
            : messageKeyMap[rawMessageKey],
        openingHours: rawOpeningHours === '-' ? undefined : rawOpeningHours,
        storeFeatures: { features, visible: !!features.length },
        storeLocation,
        storeContact,
        storeOpeningHours,
        storeSpecialOpeningDays,
        streetAddress: address?.line1,
        postalCode: address?.postalCode,
        town: address?.town,
        flyerURL,
        localBusinessStructuredData,
      },
      hasError: false,
    };
  }

  return { hasError: false, storeData: undefined };
};

export default useStoreInfoCmsData;
