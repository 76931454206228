import { Stack } from '@components/atoms/Stack';

import { ReactNode } from 'react';
import MapComponent from './components/MapComponent/MapComponent';
import ParagraphMessage from './components/ParagraphMessage/ParagraphMessage';
import StoreHero from './components/StoreHero';
import { StoreInfoMobile } from './components/StoreInfo/StoreInfoMobile';
import StoreWeeklyFlyer from './components/StoreWeeklyFlyer';
import WithStoreInfo from './components/WithStoreInfo';
import useStoreInfoCmsData from './hooks/useStoreInfoCmsData';
import { MainContentWrapper, MessageAndMapWrapper, Section } from './StorePage.styles';

interface Props {
  children?: ReactNode[];
  rightContentComponents: MultiColumnContainerComponentType[];
}

const StorePage = ({ children, rightContentComponents }: Props) => {
  const { storeData } = useStoreInfoCmsData(rightContentComponents);

  if (!storeData) {
    return null;
  }

  const { storeName, openStatusKey, openingHours, flyerURL } = storeData;

  return (
    <WithStoreInfo storeInfo={storeData}>
      <MainContentWrapper>
        <Stack>
          <StoreHero storeName={storeName} openStatusKey={openStatusKey} openingHours={openingHours} />
          <StoreInfoMobile storeInfo={storeData} />
          {!!flyerURL && <StoreWeeklyFlyer flyerLink={flyerURL} />}
          <MessageAndMapWrapper>
            <Section>
              <ParagraphMessage />
            </Section>
            <Section>{storeData && <MapComponent storeData={storeData} />}</Section>
          </MessageAndMapWrapper>
          {children}
        </Stack>
      </MainContentWrapper>
    </WithStoreInfo>
  );
};

export default StorePage;
