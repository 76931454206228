import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import StorePage from '@features/storepage/StorePage';

interface Props {
  cmsPage: PageWithSlots;
  slotList: string[];
}

const StoreTemplate = ({ cmsPage, slotList }: Props) => {
  const { MainContent2, RightContent } = collectSlots(cmsPage.slots, slotList);
  const mainContentComponents = MainContent2?.component || [];
  const rightContentComponents = RightContent?.component || [];

  return (
    <StorePage rightContentComponents={rightContentComponents}>
      {mainContentComponents.map((component: CmsComponent, index) => (
        <CmsComponent key={component.uuid} component={component} slotName="MainContent2" slotPosition={index} />
      ))}
    </StorePage>
  );
};

export default StoreTemplate;
